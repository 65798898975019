import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {IStore} from 'redux/interface';
import {Action} from 'redux';
import {Auth0Client} from '@auth0/auth0-spa-js';
import {IUser} from './interfaces';
import {IUserProfile} from '@wholesalechange/chatcomponent';
import {
  clearUserAction, finishAuthorizationAction, loginErrorActionClear,
  loginErrorActionSet,
  setBotAvatarsAction,
  setUserAction, setUserAvatarsAction,
  setUserLanguageAction, updateBotAvatarAction, updateUserAvatarAction
} from './actions';
import {setAxiosHeaders} from 'redux/api/helpers';
import {getUserProfileApi, updateUserProfileApi} from 'redux/api/user';
import {getAvatarsApi} from 'redux/api/media';
import {loadMediaAction} from 'redux/media/mediaAsyncActions';
import {errorHandler} from 'redux/errorHandler';
import {changeLocale} from '../translation/translationAsyncActions';
import {ToastKey} from 'components/gritx-toast/ToastKeyEnum';

const loginErrorHandler = async ({
  dispatch,
  auth0Client
}: { dispatch: ThunkDispatch<IStore, unknown, Action>, auth0Client: Auth0Client }) => {
  try {
    await auth0Client.logout();
  } catch (e) {
    console.log('loginErrorHandler', e);
  }
  dispatch(loginErrorActionSet());
};

export const startLoginAction = (appRedirectUri?: string): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();

  dispatch(loginErrorActionClear());
  try {
    let queryString = '';

    if (appRedirectUri) {
      const searchParams = new URLSearchParams();

      searchParams.append('appRedirectUri', appRedirectUri);
      queryString = searchParams.toString();
    }

    await auth0Client.loginWithRedirect({
      authorizationParams: {
        prompt: 'login',
        redirect_uri: `${window.location.origin}/callback${queryString && `?${queryString}`}`
      }
    });
  } catch (e) {
    console.log('startLogin error', e.message);
    await loginErrorHandler({
      dispatch,
      auth0Client
    });
  }
};

export const getUserData = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch) => {
  try {
    const {data: userData} = await getUserProfileApi();

    if (userData?.userAvatarId) {
      dispatch(loadMediaAction(userData.userAvatarId));
    }

    if (userData?.dialogAvatarId) {
      dispatch(loadMediaAction(userData.dialogAvatarId));
    }

    if (userData?.language) {
      dispatch(changeLocale(userData.language));
    }

    dispatch(setUserLanguageAction(userData));
  } catch (e) {
    console.log('user error', e.message);
    dispatch(errorHandler({
      toastKey: ToastKey.GetUserData,
      actionKey: 'getUserData',
      error: e,
      currentAction: getUserData()
    }));
  }
};

export const getBotAvatars = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  try {
    const {data: botAvatars} = await getAvatarsApi('BOT');
    const {media} = getState();

    const updatedAvatars = botAvatars.map((avatar) => {
      if (!avatar.fileUrl) {
        return {
          ...avatar,
          fileUrl: media[avatar.fileId] || ''
        };
      }

      return avatar;
    });

    updatedAvatars.forEach((avatar) => {
      if (!avatar.fileUrl) {
        dispatch(loadMediaAction(
          avatar.fileId,
          (url: string | null) => {
            if (url) {
              dispatch(updateBotAvatarAction({
                ...avatar,
                fileUrl: url
              }));
            }
          }
        ));
      }
    });
    dispatch(setBotAvatarsAction(updatedAvatars));
  } catch (e) {
    console.log('user error', e.message);
    dispatch(errorHandler({
      toastKey: ToastKey.GetBotAvatars,
      actionKey: 'getBotAvatars',
      error: e
    }));
  }
};

export const getUserAvatars = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  try {
    const {data: userAvatars} = await getAvatarsApi('USER');
    const {media} = getState();

    const updatedAvatars = userAvatars.map((avatar) => {
      if (!avatar.fileUrl) {
        return {
          ...avatar,
          fileUrl: media[avatar.fileId] || ''
        };
      }

      return avatar;
    });

    updatedAvatars.forEach((avatar) => {
      if (!avatar.fileUrl) {
        dispatch(loadMediaAction(
          avatar.fileId,
          (url) => {
            if (url) {
              dispatch(updateUserAvatarAction({
                ...avatar,
                fileUrl: url
              }));
            }
          }
        ));
      }
    });

    dispatch(setUserAvatarsAction(updatedAvatars));
  } catch (e) {
    console.log('user error', e.message);
    dispatch(errorHandler({
      toastKey: ToastKey.GetUserAvatars,
      actionKey: 'getUserAvatars',
      error: e
    }));
  }
};

export const updateUserProfileAction = (profile: IUserProfile): ThunkAction<void, IStore, unknown, Action> => async (dispatch) => {
  try {
    const {data} = await updateUserProfileApi(profile);

    dispatch(setUserLanguageAction(data));
  } catch (e) {
    console.log('updateUserProfileAction', e.message);
    dispatch(errorHandler({
      toastKey: ToastKey.UpdateUserProfileAction,
      actionKey: 'updateUserProfileAction',
      error: e
    }));
  }
};

export const finishLoginAction = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();

  try {
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser() as IUser;
    const token = await auth0Client.getTokenSilently();

    setAxiosHeaders(user, token);

    dispatch(loginErrorActionClear());
    dispatch(setUserAction(user));
    dispatch(getUserData());
  } catch (e) {
    console.log('finishLogin error', e.message);
    await loginErrorHandler({
      dispatch,
      auth0Client
    });
  }
};

export const logoutAction = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();
  dispatch(loginErrorActionClear());

  try {
    await auth0Client.logout({
      logoutParams: {
        returnTo: document.location.origin
      }
    });
    dispatch(clearUserAction());
  } catch (e) {
    console.log('logoutAction error', e.message);
    await loginErrorHandler({
      dispatch,
      auth0Client
    });
  }
};

export const getAuthorizationAction = (): ThunkAction<void, IStore, unknown, Action> => async (dispatch, getState) => {
  const {auth: {auth0Client} } = getState();

  dispatch(loginErrorActionClear());

  try {
    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser() as IUser;
      const token = await auth0Client.getTokenSilently();

      setAxiosHeaders(user, token);
      dispatch(setUserAction(user as IUser));
      dispatch(getUserData());
    }
    dispatch(finishAuthorizationAction());
  } catch (e) {
    console.log('getAuthorizationAction error', e.message);
    await loginErrorHandler({
      dispatch,
      auth0Client
    });
  }
};
