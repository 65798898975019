import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {BotType} from 'utils/hooks/use-chat-bot/interfaces';
import {TranslationKeys} from 'utils/constants/routeConstants';
import {IStore} from 'redux/interface';
import {getTranslations} from 'redux/pageAsyncActions';
import {closeArticle} from 'redux/content/actions';

import BotChat from 'components/bot-chat';
import {ReactComponent as ChatIcon} from 'assets/image/menu-auth/chat.svg';

import './styles.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {getMainDialogueApi, removeDialogueApi} from '../../redux/api/dialogue';

export const Chat: React.FunctionComponent = () => {
  const {
    status: {loading}
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {restart} = useParams();
  const history = useNavigate();

  useEffect(() => {
    dispatch(closeArticle());
  }, []);

  const removeMainDialogue = async () => {
    const {data} = await getMainDialogueApi();

    if (data != null) {
      await removeDialogueApi(data.id);
      history('/chat', {replace: true});
    }
  };

  useEffect(() => {
    if (restart) {
      if (restart === 'restart') {
        removeMainDialogue();
      } else {
        history('/chat', {replace: true});
      }
    }
  }, [restart]);

  useGetTranslations({
    translationKey: TranslationKeys.Chat
  });

  return <div className="chat">
    {
      !restart && !loading.includes(getTranslations) && <>
        <BotChat mode={BotType.Chat} title={<FormattedMessage
          id={'gritx.chat.title'}
          defaultMessage={'Chat'}
        />}/>
      </>
    }
  </div>;
};
