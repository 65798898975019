import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBOZliq7OB3NNG7k9hYDBDYj0-nCmIbsE0',
  authDomain: 'verbalize-app.firebaseapp.com',
  projectId: 'verbalize-app',
  storageBucket: 'verbalize-app.appspot.com',
  messagingSenderId: '1040926406394',
  appId: '1:1040926406394:web:b44ee491975f524068fb43',
  measurementId: 'G-G0VH5WRSD7',
  vapidKey: 'BBEp_MuqdD4Kzn_UPH8fgJnMrWtXsTdjH9o-uttd3ih2qRPQX17Rkz2_n1q4k-bMokKtcL6C5bu0DmoEzdueOEE'
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = (): Promise<string | null> => {
  return getToken(messaging, {vapidKey: firebaseConfig.vapidKey})
    .then((currentToken) => {
      console.log(`token: ${currentToken}`);
      if (currentToken) {
        return currentToken;
      }
      console.log('No registration token available. Request permission to generate one.');

      return null;
    })
    .catch((err) => {
      console.log(`An error occurred while retrieving token - ${err}`);

      return null;
    });
};

onMessage(messaging, (message) => {
  console.log(`onMessage call, payload = ${message}`);

  return new Notification(<string>message.notification?.title, {
    body: message.notification?.body,
    icon: message.notification?.icon
  });
});
