import {ITranslationActionHandler, ITranslationInitialState, IUpdateTranslations} from './interfaces';
import {CHANGE_LOCALE, TranslationActionsType, UPDATE_TRANSLATIONS} from './actions';
import {SystemLanguage} from 'utils/constants/constants';

const translationInitialState: ITranslationInitialState = {
  locale: SystemLanguage.English,
  translationKeys: [],
  translations: {
    en: {},
    es: {},
    zh: {},
    fr: {},
    de: {},
    hi: {},
    ml: {},
    pt: {},
    fil: {},
    ta: {},
    vi: {},
    ja: {},
    prs: {},
    ps: {},
    ko: {}
  }
};

function changeLocale({
  state,
  payload
}: ITranslationActionHandler): ITranslationInitialState {
  return {
    ...state,
    locale: payload as string
  };
}

function updateTranslations({
  state,
  payload
}: ITranslationActionHandler): ITranslationInitialState {
  const {
    locale,
    translationKey,
    translations: newTranslations
  } = payload as IUpdateTranslations;
  const translationsList = {
    ...state.translations[locale],
    ...newTranslations
  };
  const translationKeys = state.translationKeys;

  if (translationKey && !translationKeys.includes(translationKey)) {
    translationKeys.push(translationKey);
  }

  return {
    ...state,
    translationKeys: [...translationKeys],
    translations: {
      ...state.translations,
      [locale]: {...translationsList}
    }
  };
}

const translationReducerHandlers = new Map([
  [CHANGE_LOCALE, changeLocale],
  [UPDATE_TRANSLATIONS, updateTranslations]
]);

const translationReducer = (state = translationInitialState, action: TranslationActionsType): ITranslationInitialState => {
  const handler = translationReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<ITranslationActionHandler>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default translationReducer;
