import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {IStore} from 'redux/interface';
import {dialogueAsyncActions, startXpedition, getTranslations, restartXpedition} from 'redux/pageAsyncActions';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {BotType} from 'utils/hooks/use-chat-bot/interfaces';
import {TranslationKeys} from 'utils/constants/routeConstants';

import BotChat from 'components/bot-chat';

import {closeArticle} from 'redux/content/actions';
import {ReactComponent as XpeditionIcon} from 'assets/image/menu-auth/xpedition.svg';

import './styles.scss';
import {useNavigate, useParams} from 'react-router-dom';

export const XpeditionDialogue: React.FunctionComponent = () => {
  const {
    status: {loading},
    dialogue: {
      dialogue
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const [dialogueId, setDialogueId] = useState<number>();
  const {xpeditionId, restart} = useParams();
  const history = useNavigate();

  useEffect(() => {
    dispatch(closeArticle());
  }, []);

  useEffect(() => {
    if (dialogue) {
      setDialogueId(dialogue.id);
    }
  }, [dialogue]);

  useEffect(() => {
    if (xpeditionId) {
      if (restart === 'restart') {
        dispatch(dialogueAsyncActions[restartXpedition](Number(xpeditionId)));
        history(`/xpedition/${xpeditionId}`, {replace: true});
      } else {
        dispatch(dialogueAsyncActions[startXpedition](Number(xpeditionId)));
      }
    }
  }, [xpeditionId]);

  useGetTranslations({
    translationKey: TranslationKeys.Chat
  });

  return <div className="xpedition-dialogue">
    {
      !loading.includes(getTranslations) && <>
        <BotChat mode={BotType.Xpedition} dialogueId={dialogueId} title={<FormattedMessage
          id={'gritx.xpedition.title'}
          defaultMessage={'Journeys'}
        />} />
      </>
    }
  </div>;
};
