import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {FormattedMessage, useIntl} from 'react-intl';

import {IStore} from 'redux/interface';
import {
  createJournal,
  getDialogues,
  getJournals,
  getTranslations,
  journalAsyncActions, removeJournal
} from 'redux/pageAsyncActions';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {TranslationKeys} from 'utils/constants/routeConstants';

import {ReactComponent as ChatIcon} from 'assets/image/menu-auth/chat.svg';

import './styles.scss';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import NotFoundInfo from '../../components/not-found-info';
import CrossroadImg from '../../assets/image/library/skill-crossroad.svg';
import Loader from '../../components/loader';
import {useNavigate} from 'react-router-dom';
import {IJournalDialogue} from '../../redux/journal/interfaces';
import {JournalModalWindow} from '../../components/journal-modal-window/JournalModalWindow';
import JournalsCardSection from '../../components/journals-card-section';
import {setJournal} from '../../redux/journal/actions';
import {logoutAction} from '../../redux/auth/authAsyncActions';
import {IRadioInput} from '../../components/input-radio/interfaces';
import InputRadio from '../../components/input-radio';

export const Journals: React.FunctionComponent = () => {
  const {
    journal: {
      journals
    },
    status: {
      loading
    },
    auth: {
      relogin
    }
  } = useSelector((state: IStore) => state);
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newJournal, setNewJournal] = useState<IJournalDialogue>({
    dialogId: 0,
    dialogName: '',
    dialogUserDate: new Date()
  });
  const [isShowCreateJournal, setShowCreateJournal] = useState(false);
  const [sortedJournals, setSortedJournals] = useState<IJournalDialogue[]>([]);
  const [sort, setSort] = useState<string>('new');
  const shouldProcessRelogin = useRef(true);

  useEffect(() => {
    if (shouldProcessRelogin.current) {
      shouldProcessRelogin.current = false;
      if (relogin) {
        dispatch(logoutAction());
      }
    }
  }, [relogin]);

  const sortRadioInputs: IRadioInput[] = [
    {
      id: 0,
      name: 'new',
      label: 'Newest',
      value: 'new'
    },
    {
      id: 1,
      name: 'old',
      label: 'Oldest',
      value: 'old'
    }];

  function getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }

  function getSortedJournals(sortMethod: string) {
    if (sortMethod === 'old') {
      return journals.slice(0)
        .sort((a, b) => getTime(a.dialogUserDate) - getTime(b.dialogUserDate));
    }

    return journals.slice(0)
      .sort((a, b) => getTime(b.dialogUserDate) - getTime(a.dialogUserDate));
  }

  useEffect(() => {
    setSortedJournals(getSortedJournals(sort));
  }, [journals]);

  useEffect(() => {
    if (journals) {
      setSortedJournals(getSortedJournals(sort));
    }
  }, [sort]);

  // useEffect(() => {
  //   if (relogin) {
  //     if (relogin === 'relogin') {
  //       dispatch(logoutAction());
  //     } else {
  //       history('/journals', {replace: true});
  //     }
  //   }
  // }, [relogin]);

  useGetTranslations({
    translationKey: TranslationKeys.Journals
  });

  useEffect(() => {
    dispatch(journalAsyncActions[getJournals]());
  }, []);

  function handleCreateJournal(journal: IJournalDialogue) {
    dispatch(journalAsyncActions[createJournal](journal));
    setNewJournal({
      dialogId: 0,
      dialogName: '',
      dialogUserDate: new Date()
    });
    setShowCreateJournal(false);
  }

  function handleOpenDialogue(journal: IJournalDialogue) {
    dispatch(setJournal(journal));
    navigate('/journal');
  }

  function onCreateJournal() {
    setShowCreateJournal(true);
  }

  function handleCloseJournal() {
    setNewJournal({
      dialogId: 0,
      dialogName: '',
      dialogUserDate: new Date()
    });
    setShowCreateJournal(false);
  }

  function handleChangeSort(value: string) {
    setSort(value);
  }

  function renderJournalCards() {
    return <div className="journal__cards">
      {journals.length === 0 ? <NotFoundInfo
        title={intl.formatMessage({
          id: 'gritx.dialogues.sectionCards.notFound',
          defaultMessage: 'Sorry, but nothing can be found.'
        })}
        image={CrossroadImg}
      /> : <JournalsCardSection
        showLoader={loading.includes(getDialogues)}
        cards={sortedJournals}
        onClickCard={handleOpenDialogue}
      />}
    </div>;
  }

  return <section className={'journals container'}>
    {
      !loading.includes(getTranslations) && <>
        <h1 className="journals__title">
          <ChatIcon className="journals__icon"/>
          <FormattedMessage
            id={'gritx.journals.title'}
            defaultMessage={'journals'}
          />
        </h1>
        <div className="journals__header">
          <div className="journals__header-sort">
            <InputRadio inputs={sortRadioInputs} value={sort} onChange={handleChangeSort} label="Sort by:"/>
          </div>
          <GritxButton
            title={intl.formatMessage({
              id: 'gritx.journals.create',
              defaultMessage: 'Create'
            })}
            className={'journals__button'}
            variant={ButtonVariant.Primary}
            onClick={onCreateJournal}
          />
        </div>
        {
          loading.includes(getJournals) || loading.includes(removeJournal)
          || loading.includes(createJournal) ? <Loader/> : renderJournalCards()
        }
        <JournalModalWindow
          journalCard={newJournal}
          title={'Create a new Journal'}
          onSave={handleCreateJournal}
          onClose={handleCloseJournal}
          show={isShowCreateJournal}
        />
      </>
    }
  </section>;
};
