import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {IStore} from 'redux/interface';
import {getTranslations} from 'redux/pageAsyncActions';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {BotType} from 'utils/hooks/use-chat-bot/interfaces';
import {TranslationKeys} from 'utils/constants/routeConstants';

import BotChat from 'components/bot-chat';

import {closeArticle} from 'redux/content/actions';
import {ReactComponent as XpeditionIcon} from 'assets/image/menu-auth/xpedition.svg';

import './styles.scss';
import {dialogueClearedAction} from '../../redux/dialogue/actions';

export const Xpedition: React.FunctionComponent = () => {
  const {
    status: {loading}
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closeArticle());
    dispatch(dialogueClearedAction());
  }, []);

  useGetTranslations({
    translationKey: TranslationKeys.Chat
  });

  return <div className="xpedition">
    {
      !loading.includes(getTranslations) && <>
        <h1 className="xpedition__title">
          <XpeditionIcon className="xpedition__icon"/>
          <FormattedMessage
            id={'gritx.xpedition.title'}
            defaultMessage={'Journeys'}
          />
        </h1>
        <BotChat mode={BotType.XpeditionList}/>
      </>
    }
  </div>;
};
