import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {IStore} from 'redux/interface';
import {getTranslations} from 'redux/pageAsyncActions';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {BotType} from 'utils/hooks/use-chat-bot/interfaces';
import {TranslationKeys} from 'utils/constants/routeConstants';

import BotChat from 'components/bot-chat';

import {closeArticle} from 'redux/content/actions';
import {ReactComponent as XpeditionIcon} from 'assets/image/menu-auth/xpedition.svg';

import './styles.scss';
import {Link, useNavigate} from 'react-router-dom';

export const JournalDialogue: React.FunctionComponent = () => {
  const {
    status: {loading},
    journal: {
      journal
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const [dialogueId, setDialogueId] = useState<number>();
  const history = useNavigate();

  useEffect(() => {
    dispatch(closeArticle());
  }, []);

  useEffect(() => {
    if (journal) {
      setDialogueId(journal.dialogId);
    } else {
      history('/journals');
    }
  }, [journal]);

  useGetTranslations({
    translationKey: TranslationKeys.Chat
  });

  function renderBreadcrumbs() {
    return <div className="breadcrumbs">
      <Link className="breadcrumbs__previous" to="/journals">
        <FormattedMessage
          id={'gritx.content-page.Journals.breadcrumb.previous'}
          defaultMessage="Journals"
        />
      </Link>
    </div>;
  }

  return <div className="journal">
    {
      !loading.includes(getTranslations) && journal && <>
        <h1 className="journal__title">
          <XpeditionIcon className="journal__icon"/>
          {renderBreadcrumbs()}
        </h1>
        <BotChat mode={BotType.Xpedition} dialogueId={dialogueId} title={<>{journal.dialogName}</>} />
      </>
    }
  </div>;
};
